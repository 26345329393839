<template>
  <div class="constrain part">
    <div class="row-12">
      <div class="md:col-9 md:offset-3">

        <form ref="contactForm" @submit.prevent="saveProfile">
          <Transition name="appear">
            <div v-if="response.general" class="flex row-12 mb-8">
              <div class="md:col-6 flex flex-col">
                <div :class="[response.general.type]" class="response">
                  {{ response.general.message }}
                </div>
              </div>
            </div>
          </Transition>
          <div class="flex row-12 mb-8">
            <div class="md:col-6 flex flex-col">
              <label for="first_name">{{ payload.vorname }}</label>
              <input
                id="first_name"
                v-model="firstName"
                name="first_name"
                type="text"
                required
              >
            </div>
          </div>
          <div class="flex row-12 mb-8">
            <div class="md:col-6 flex flex-col">
              <label for="last_name">{{ payload.nachname }}</label>
              <input
                id="last_name"
                v-model="lastName"
                name="last_name"
                type="text"
                required
              >
            </div>
          </div>
          <div class="flex row-12 mb-8">
            <div class="md:col-6 flex flex-col">
              <label for="email">{{ payload.email }}</label>
              <input
                id="email"
                v-model="email"
                name="email"
                type="email"
                required
              >
            </div>
          </div>
          <div class="flex row-12 mb-8">
            <div class="col-12">
              <button type="submit" class="util-btn uppercase">
                {{ payload.speichern }}
              </button>
            </div>
          </div>
        </form>

        <hr class="my-12">

        <form @submit.prevent="savePassword">
          <Transition name="appear">
            <div v-if="response.password" class="flex row-12 mb-8">
              <div class="md:col-6 flex flex-col">
                <div :class="[response.password.type]" class="response">
                  {{ response.password.message }}
                </div>
              </div>
            </div>
          </Transition>
          <div class="flex row-12 mb-8">
            <div class="md:col-6 flex flex-col">
              <label for="pwCurrent">{{ payload.curPassword }}</label>
              <input
                id="pwCurrent"
                v-model="pwCurrent"
                name="pwCurrent"
                type="password"
                autocomplete="current-password"
                required
              >
            </div>
          </div>
          <div class="flex row-12 mb-8">
            <div class="md:col-6 flex flex-col">
              <label for="pwNew">{{ payload.newPassword }}</label>
              <input
                id="pwNew"
                v-model="pwNew"
                name="pwNew"
                type="password"
                autocomplete="new-password"
                required
              >
            </div>
          </div>
          <div class="flex row-12 mb-8">
            <div class="md:col-6 flex flex-col">
              <label for="pwRepeat">{{ payload.repPassword }}</label>
              <input
                id="pwRepeat"
                v-model="pwRepeat"
                name="pwRepeat"
                type="password"
                autocomplete="new-password"
                required
              >
            </div>
          </div>
          <div class="flex row-12 pb-12 mb-12">
            <div class="col-12">
              <button type="submit" class="util-btn uppercase">
                {{ payload.passwortAendern }}
              </button>
            </div>
          </div>

        </form>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
  data() {
    return {

      firstName: this.$store.state.user.first_name,
      lastName: this.$store.state.user.last_name,
      email: this.$store.state.user.email,

      pwCurrent: '',
      pwNew: '',
      pwRepeat: '',

      response: { },

    };
  },
  computed: {
    locale() {
      return this.$store.state.router.locale;
    },
  },
  methods: {

    async saveProfile() {
      const body = new FormData();
      body.append('general_info[first_name]', this.firstName);
      body.append('general_info[last_name]', this.lastName);
      body.append('general_info[email]', this.email);
      const response = await fetch(
        `${this.$store.state.router.base}/auth/edit`,
        { method: 'POST', credentials: 'include', body },
      );
      const data = await response.json();
      this.response = { general: data };
    },

    async savePassword() {
      const body = new FormData();
      body.append('change_password[old]', this.pwCurrent);
      body.append('change_password[new]', this.pwNew);
      body.append('change_password[new_2]', this.pwRepeat);
      const response = await fetch(
        `${this.$store.state.router.base}/auth/edit`,
        { method: 'POST', credentials: 'include', body },
      );
      const data = await response.json();
      this.response = { password: data };
    },

  },
};
</script>

<style lang="scss" scoped>

.response {
  @apply p-4 bg-bluegray rounded;
  color: white;
  &.error { @apply bg-red }
  &.success { background: #45a215 }
}

</style>
